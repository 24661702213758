export const daysOfWeek = [
  {
    code: 'Mon',
    text: 'จันทร์'
  },
  {
    code: 'Tue',
    text: 'อังคาร'
  },
  {
    code: 'Wed',
    text: 'พุธ',
  },
  {
    code: 'Thu',
    text: 'พฤหัสบดี'
  },
  {
    code: 'Fri',
    text: 'ศุกร์'
  },
  {
    code: 'Sat',
    text: 'เสาร์'
  },
  {
    code: 'Sun',
    text: 'อาทิตย์'
  }
]
