<template>
  <span>
    <button v-if="status==='Active'" class="btn btn-sm btn-link" @click="edit()">แก้ไข</button>
    <button v-if="status==='Active'" class="btn btn-sm btn-link text-danger" @click="deleteMarket()">ลบ</button>
    <button v-if="status==='Deleted'" class="btn btn-sm btn-link text-secondary" @click="rollback()">เรียกคืน</button>
    <button v-if="status==='Deleted'" class="btn btn-sm btn-link text-danger" @click="permanentDelete()">ลบถาวร</button>
  </span>
</template>
<script>
export default {
  name: 'MarketButtons',
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  computed: {
    status() {
      if(this.data) {
        return this.data.status
      }else{
        return null
      }
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.data)
    },
    deleteMarket() {
      this.$emit('delete', this.data._id)
    },
    rollback() {
      this.$emit('rollback', this.data._id)
    },
    permanentDelete() {
      this.$emit('permanentDelete', this.data._id)
    }
  }
}
</script>
