<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>รายชื่อหวย</strong>
      <button class="btn btn-sm btn-link add-market-btn" @click="addMarket">เพิ่มหวย</button>
    </div>

    <div class="card">
      <table class="table table-market table-sm table-hover table-bordered mb-0">
        <thead>
          <tr>
            <th class="text-center" width="10%">ลำดับ</th>
            <th class="text-center" width="15%">เวลา</th>
            <th class="text-center" width="25%">หวย</th>
            <th class="text-center" width="15%">สถานะ</th>
            <th class="text-center" width="5%">ID</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="group in groups" v-if="group.items.length > 0" :key="group._id">
          <tr>
            <th colspan="6">{{ group.groupTitle }}</th>
          </tr>
          <tr v-for="item in group.items" :key="item._id">
            <td class="text-center">{{ item.sort }}</td>
            <td class="text-center">{{ item.time }}</td>
            <td>{{ item.marketTitle }}</td>
            <td class="text-center"><MarketStatus :status="item.status" /></td>
            <td class="text-center">{{ item.apiProductId }}</td>
            <td class="text-right">
              <MarketButtons :data="item" @edit="edit" @delete="deleteMarket" @rollback="rollback" @permanentDelete="permanentDelete" />
            </td>
          </tr>
        </tbody>
        <tbody v-if="items.length === 0">
          <tr>
            <td colspan="6" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <MarketModal :data="data" :is-show="isShowMarketModal" @close="modalClosed" />
  </div>
</template>
<script>
import MarketModal from './components/MarketModal'
import MarketStatus from './components/MarketStatus'
import MarketButtons from './components/MarketButtons'

import _ from 'lodash'
import MarketService from '@/services/marketService'

import Swal from 'sweetalert2'

const marketInterface = {
  marketTitle: '',
  group: {
    groupId: null,
    groupTitle: null
  },
  time: '00:00:00',
  openDays: [],
  status: 'Active',
  sort: 0,
  apiProductId: '',
  url: ''
}

export default {
  name: 'Markets',
  components: {
    MarketModal,
    MarketStatus,
    MarketButtons
  },
  data() {
    return {
      isShowMarketModal: false,
      markets: null,
      data: marketInterface
    }
  },
  computed: {
    items() {
      return this.markets || []
    },
    groups() {
      return this.$store.state.marketGroups.map((g)=>{
        return {
          ...g,
          items: this.items.filter((m)=>{
            return g._id === m.group.groupId
          })
        }
      })
    }
  },
  methods: {
    loadMarkets() {
      MarketService.getMarkets().then((response)=>{
        if(response.success) {
          this.markets = response.data
        }
      })
    },
    addMarket() {
      this.data = JSON.parse(JSON.stringify(marketInterface))

      const maxBy = _.maxBy(this.items, (i)=>i.sort)
      this.data.sort = maxBy?.sort ? maxBy?.sort +1 : 1

      this.isShowMarketModal = true
    },
    edit(item) {
      this.data = {
        ...marketInterface,
        ...item
      }
      this.isShowMarketModal = true
    },
    deleteMarket(_id) {
      Swal.fire({
        title: 'ยืนยันการลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.deleteMarket(_id)
          .then((response)=>{
            if(response.success) {
              this.loadMarkets()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    rollback(_id) {
      MarketService.rollbackMarket(_id)
      .then((response)=>{
        if(response.success) {
          this.loadMarkets()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เรียกคืนกลุ่มเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'เรียกคืนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    permanentDelete(_id) {
      console.log(_id)
      Swal.fire({
        title: 'ต้องการลบถาวร?',
        text: 'ลบถาวรจะไม่สามารถเรียกคืนได้อีก',
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.permanentDeleteMarket(_id)
          .then((response)=>{
            if(response.success) {
              this.loadMarkets()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบออกจากฐานข้อมูลเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    modalClosed(needReload) {
      this.isShowMarketModal = false
      if(needReload) {
        this.loadMarkets()
      }
    },
  },
  mounted() {
    this.loadMarkets()
    this.$store.dispatch('reloadMarketGroups')
  }
}
</script>
<style lang="scss" scoped>
.table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
