<template>
  <b-modal
    ref="add-market-modal"
    hide-footer
    :title="modalTitle"
    ok-variant="success"
    :ok-title="modalTitle"
    cancel-title="ยกเลิก"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      id="market-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ชื่อหวย"
        label-for="input-market-title"
        :invalid-feedback="validate.marketTitle.text"
        label-class="text-primary"
      >
        <b-form-input
          id="input-market-title"
          v-model="input.marketTitle"
          type="text"
          placeholder="ชื่อหวย"
          required
          :state="validate.marketTitle.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="กลุ่มหวย"
        label-for="input-market-group"
        :invalid-feedback="validate.marketTitle.text"
        label-class="text-primary"
      >
        <b-form-select
          v-model="input.group.groupId"
          :options="groupOptions"
          required
          :state="validate.group.status"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="วันที่เปิดแทง"
        label-class="text-primary"
      >
        <b-form-checkbox-group
          id="input-market-open-days"
          v-model="input.openDays"
          name="input-market-open-days"
        >
          <b-form-checkbox v-for="day in daysOfWeek" :key="day.code" :value="day.code">{{ day.text }}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <b-form-group
        id="market-time"
        label="เวลาออกผล"
        label-class="text-primary"
      >
        <b-form-timepicker v-model="input.time" locale="th" :hour12="false" :no-close-button="true"></b-form-timepicker>
      </b-form-group>

      <b-form-group
        label="เรียงลำดับ"
        label-for="input-market-sort"
        :invalid-feedback="validate.sort.text"
        label-class="text-primary"
      >
        <b-form-input
          id="input-market-sort"
          v-model="input.sort"
          type="text"
          placeholder="เรียงลำดับ"
          required
          :state="validate.sort.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="API productId"
        label-for="input-market-apiProductId"
        label-class="text-primary"
      >
        <b-form-input
          id="input-market-apiProductId"
          v-model="input.apiProductId"
          type="text"
          placeholder="productId"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ลิ้งค์ดูผล"
        label-for="input-market-url"
        :invalid-feedback="validate.url.text"
        label-class="text-primary"
      >
        <b-form-input
          id="input-market-url"
          v-model="input.url"
          type="text"
          placeholder="https://"
          required
          :state="validate.url.status"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess || !input.marketTitle">{{isProcess ? 'กำลังบันทึก...' : modalTitle}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import { daysOfWeek } from '@/configs/market.config'
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'

const validations = {
  marketTitle: {
    status: null,
    text: ''
  },
  group: {
    status: null,
    text: ''
  },
  sort: {
    status: null,
    text: ''
  },
  url: {
    status: null,
    text: ''
  }
}

const marketInterface = {
  marketTitle: '',
  group: {
    groupId: null,
    groupTitle: null
  },
  openDays: [],
  time: '00:00:00',
  status: 'Active',
  sort: 0,
  apiProductId: '',
  url: ''
}

export default {
  name: 'MarketModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: marketInterface
    }
  },
  data() {
    return {
      input: marketInterface,
      isProcess: false,
      needReload: false,
      validate: JSON.parse(JSON.stringify(validations))
    }
  },
  computed: {
    modalTitle() {
      return this.input._id ? 'แก้ไขหวย' : 'เพิ่มหวย'
    },
    groupOptions() {
      return this.$store.state.marketGroups.reduce((groups, item)=>{
        groups.push({
          value: item._id,
          text: item.groupTitle
        })
        return groups
      }, [{value: null, text: 'เลือกกลุ่มหวย'}])
    },
    daysOfWeek() {
      return daysOfWeek
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.validate = JSON.parse(JSON.stringify(validations))
    },
    showModal() {
      this.$refs['add-market-modal'].show()
    },
    hideModal() {
      this.$refs['add-market-modal'].hide()
    },
    handleSubmit() {
      this.validate = JSON.parse(JSON.stringify(validations))
      this.input.sort = parseInt(this.input.sort)

      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === this.input.group.groupId
      })

      this.input.group.groupTitle = group.groupTitle
      if(this.input._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      if(this.input.marketTitle) {
        MarketService.saveMarket(this.input)
        .then((response)=>{
          if(response.success) {
            this.needReload = true
            this.hideModal()
          }else{
            throw new Error(response?.message)
          }
        })
        .catch((e)=>{

          e?.errors.forEach((item)=>{
            this.validate[item.source.parameter] = {
              status: false,
              text: item?.detail
            }
          })

          Swal.fire({
            title: 'ผิดพลาด!',
            text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    },
    update() {
      if(this.input.marketTitle) {
        MarketService.updateMarket(this.input._id, this.input)
        .then((response)=>{
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw {
              massage: response
            }
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>

</style>
